import React from "react";
function Projects() {
    return(
    <div className="eventOuter">
      <div className="container">
        <div className="card-group vgr-cards">
        <h1>dfgdfgdgdfg</h1>
            <div className="card">
                <div className="card-img-body">
                </div>
                <div className="card-body">
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                <h4 className="card-title">Projects </h4>
                <iframe title="project2" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fslamidwestusa%2Fposts%2Fpfbid0CypNAXqLiin13yDpHCspDUymybwA2MsqmgzuuoLcyax9faFurcMMfwvMMPp6wVGXl&show_text=true&width=500&is_preview=true" width="500" height="580" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                <iframe title="project" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fslamidwestusa%2Fposts%2Fpfbid02ZHDAiGjS3R5E7EzMsF7JaGEBpEcH8vvDCouCGmZAgtfh5mmufTA6FWuNMHMRNHSCl&show_text=true&width=500&is_preview=true" width="500" height="578" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                <div className="card-body">
                <iframe title="project1" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fslamidwestusa%2Fposts%2Fpfbid0DwBoFHV5QMGzsJsfegtfb1wKNPAoep8yeJjoC8AAqaMmpAfpsLLtjfpZEKbbtqH4l&show_text=true&width=500&is_preview=true" width="500" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
            </div>
        </div>
      </div>
    </div>
    );
}
export default Projects