import React from 'react';
import MainBody from "./Components/MainBody"


import "./app.css"


const App = () => {
  return <>
    <MainBody />
    </>
}

export default App;
